import React, { useCallback } from 'react';
import { Drawer, Form, message, Input, Button } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { updateDriver } from '../../../Reducers/driver';
import DriverStatusSelector from '../../../Components/DriverStatusSelector';
import { frc } from 'front-end-common';
import DriversTypeSelector from '../../../Components/DriversTypeSelector';
import AmazonOutlined from "@ant-design/icons/lib/icons/AmazonOutlined";

const useBoolean = frc.hooks.useBoolean;

function UpdateDriver({ className, id, afterUpdate, record }) {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean(false);
  const { isLoading, error } = useSelector(state => state.driver.update, shallowEqual)
  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    dispatch(updateDriver({ id, ...data })).then(() => {
      message.success('Driver has been updated!');
      afterUpdate();
      close();
    })
  }, [id, dispatch, afterUpdate, close]);

  return (
    <>
      <Button type="primary" className={className} onClick={open}>Update driver</Button>
      <Drawer onClose={close}
              visible={isOpen}
              title="Update Driver"
              placement="right"
              width={600}
      >
        {error && <ErrorBlock message={error.message} description={error.description} />}
        <Form layout="vertical" onFinish={handleSuccess}>
          <Form.Item
            hidden={true}
            name="group"
            initialValue={record.group}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select driver.' },
            ]}
            label="Driver"
            name="driver"
            initialValue={record.driver}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select host.' },
            ]}
            label="Host"
            name="host"
            initialValue={record.host}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select port.' },
            ]}
            label="Port"
            name="port"
            initialValue={record.port}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select type.' },
            ]}
            label="Type"
            name="type"
            initialValue={record.type}
          >
            <DriversTypeSelector />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select status.' },
            ]}
            label="Status"
            name="status"
            initialValue={record.status}
          >
            <DriverStatusSelector />
          </Form.Item>
          <Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                icon={isLoading && <AmazonOutlined />}>
              Update
            </Button>
          </Form.Item>
          <Form.Item
            hidden={true}
            name="engineId"
            initialValue={record.engineId}
          >
            <Input />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateDriver;
