import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Button, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import JobFilters from '../../Components/JobFilters';
import { cancelOtherJobs, fetchOtherJobs } from '../../Reducers/otherJobs';
import './styles.scss';
import {LoadingOutlined} from "@ant-design/icons";
import {CloseCircleOutlined} from "@ant-design/icons/lib/icons";
import JobsTableWrapper from '../../Components/JobsTableWrapper';

const b = b_.lock('OtherJob');

function OtherJob() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTableState(state => state.otherJobs.list, fetchOtherJobs);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.otherJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelOtherJobs(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  return <>
    <Title>
      Other Jobs
    </Title>
    <JobFilters filters={filters} onFilter={onFilter} className="mb" withCodeFilter={false} />
    <JobsTableWrapper
        jobTableType='other_jobs_table'
        jobsList={list}
        onChange={onChange}
        pagination={pagination}
        isLoading={isLoading}
        fetch={fetch}
        onFilter={onFilter}
        filters={filters}
      />
  </>;
}

export default OtherJob;
