import React, { useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import './styles.scss';
import { fetchConfigs } from '../../Reducers/config';
import {getProxies} from "../../Reducers/proxies";
import CreateProxy from "./CreateProxy";
import ProxiesTable from "./ProxiesTable";
import {fetchAccountsGroups} from "../../Reducers/accounts";

const b = b_.lock('Accounts');

function Proxies() {
  const {
    pagination,
    onChange,
    isLoading,
    fetch,
    sort,
    error,
    onFilter
  } = useTableState(state => state.proxies.proxies, getProxies);

  const dispatch = useDispatch();

  const list = useSelector(state => state.proxies.proxies.payload, shallowEqual);

  const groups = useSelector(state => state.accounts.accountsGroups.payload, shallowEqual);

  useEffect(() => {
      !groups && dispatch(fetchAccountsGroups());
  },[dispatch, groups])

  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const [selectedRow, setSelectedRow] = useState([]);

  return <div className={b()}>
    <Title className="mb">
        Proxies
    </Title>
    <div className={b('buttonsBlock')}>
          <CreateProxy afterSuccess={fetch} groups={groups}/>
    </div>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <ProxiesTable selected={selectedRow}
                  groups={groups}
                   setSelected={setSelectedRow}
                   afterUpdate={fetch}
                   pagination={pagination}
                   onChange={onChange}
                   isLoading={isLoading}
                   sorter={sort}
                   proxies={list}
                   onFilter={onFilter}
    />
  </div>;
}

export default Proxies;
