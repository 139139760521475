import React, { useCallback } from 'react';
import {Button, Drawer, Form, Input, message, Popover} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import { useDispatch } from 'react-redux';
import { changePasswordAccount } from '../../../Reducers/accounts';
import {LockOutlined} from "@ant-design/icons";

function ChangePassword({ className, accountIds }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const dispatch = useDispatch();

  const handleSuccess = useCallback((params) => {
      Object.keys(params).filter(e => !!params[e]).length && dispatch(changePasswordAccount({ accountIds: [accountIds], ...params })).then(() => {
      message.success('account has been updated!');
    });
    close();
  }, [dispatch, accountIds, close]);

  return <>
      <Popover placement={"top"} content={"Change password"}>
          <LockOutlined onClick={open} />
      </Popover>
    <Drawer onClose={close}
            visible={isOpen}
            title="Change Password"
            placement="right"
            width={600}
    >
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          label="New Password"
          name="password"
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
            label="New Secret"
            name="secret"
        >
            <Input type="text" />
        </Form.Item>
        <Form.Item
            label="New SerialNum"
            name="serialNum"
        >
            <Input type="text" />
        </Form.Item>
        <Form.Item
            label="New RestoreCode"
            name="restoreCode"
        >
            <Input type="text" />
        </Form.Item>
        <Form.Item>
          <Button
              type="primary"
              htmlType="submit"
              >
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default ChangePassword;
