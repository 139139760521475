import React, { useEffect, useRef } from 'react';
import b_ from 'b_';
import {Button, Drawer, message, Select, Switch, Upload} from 'antd';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import useBoolean from '../../../Utils/useBoolean';
import GroupSelector from '../../../Components/GroupSelector';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { sendOrderPurchaseJobs } from '../../../Reducers/purchaseJobs';
import './styles.scss';

const b = b_.lock('UploadOrders');

function UploadOrders({ className, afterSuccess, accountsGroups }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { current: form } = useRef(new FormData());
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.purchaseJobs.orders, shallowEqual);

  useEffect(() => {
    form.set('isOwn', 'false');
  }, []);

  const handleSubmit = () => {
    if (!form.has('file') || !form.has('group')) {
      return message.error('Please field the form!');
    }

    dispatch(sendOrderPurchaseJobs(form))
      .then(() => {
      message.success('Order has been upload!');
      afterSuccess();
      close();
    })
  };

  const beforeUpload = (file) => {
    form.delete('file');
    form.append('file', file);
    return false;
  };

  const onRemove = () => {
    form.delete('file');
  };

  return (<>
    <Button
        type="primary"
        onClick={open}
        className={className}
        icon={<UploadOutlined />}>
      Upload Orders
    </Button>
    <Drawer visible={isOpen} title="Upload Orders" width={600} closable placement="right" onClose={close}>
      {error && <ErrorBlock className="mb" message={error.message || error} />}
      <div className={b('line')}>
        <b>Orders</b>
        <Upload accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onRemove={onRemove}
                beforeUpload={beforeUpload}
        >
          <Button
              variant="contained"
              startIcon={<UploadOutlined />}
          >Select File</Button>
        </Upload>
      </div>
      <div className={b('line')}>
        <b>Group</b>
        <GroupSelector onChange={(v) => form.set('group', v)} />
      </div>
        {accountsGroups && <div className={b('line')}>
            <b>Accounts Group</b>
            <Select
                className={className}
                disabled={true}
                mode="multiple"
                onChange={(v) => form.set('accounts_group', v)}
                style={{ width: '100%' }}
                allowClear
            >
                {accountsGroups && accountsGroups.nameGroups.map(group => <Select.Option key={group} value={group}>{group}</Select.Option>)}
            </Select>
        </div>}
      <div className={b('line')}>
        <b>Owner</b>
        <Switch style={{ width: 60 }} defaultChecked={false} onChange={(v) => form.set('isOwn', v)} />
      </div>
      <div className={b('line')}>
        <Button
            loading={isLoading}
            type="primary"
            onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Drawer>
  </>);
}

export default UploadOrders;
