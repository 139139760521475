import React from 'react';
import {Popover, Switch, Tag} from 'antd';
import './styles.scss';
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import b_ from "b_";
import PropTypes from "prop-types";
import PrintAccountState from "../Prints/PrintAccountStatus";

const b = b_.lock('EnabledJobs');

const ENABLED_JOBS = JSON.parse(process.env.REACT_APP_ENABLED_JOBS);

function EnabledJobs({ enabledJobs, handleSave, field, rec, fieldsChange }) {

    const handleUpdateTag = (job) => {
         const params = {
             accountIds: [
                 rec.id
             ]
         }

         params[field] = enabledJobs.includes(job) ? [...enabledJobs.filter(code => code !== job)]
             : [...enabledJobs.filter(em => em !== "Enabled"), job]

        return handleSave(params);
    }

    return (<div className="EnabledJobs">
      {ENABLED_JOBS.map((job) => (
          <Popover key={job} mouseEnterDelay={1.1} content={`${enabledJobs.includes(job) ? `remove from active ${job}` : `add to active ${job}` }`}>
              <Tag className={[b('tag'), enabledJobs.includes(job) ? 'EnabledJobs__tag_green' : 'EnabledJobs__tag_red']}
                   color={enabledJobs.includes(job) ? 'green' : 'red'}
              >
                  {job} <Switch
                  size="small"
                  className={enabledJobs.includes(job) ? 'switch__green' : 'switch__red'}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked = {enabledJobs.includes(job)}
                  onClick={(e) => handleUpdateTag(job)}
              />
              </Tag>
          </Popover>
      ))}
    </div>);
}

EnabledJobs.propTypes = {
    enabledJobs: PropTypes.array,
    handleSave: PropTypes.func,
    field: PropTypes.string,
    rec: PropTypes.object,
};

EnabledJobs.defaultProps = {
    className: '',
    rec: {},
};

export default EnabledJobs;
