import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Button, Col, DatePicker, Input, Row, Select } from 'antd';
import './styles.scss';
import { momentToUnix, unixToMoment } from '../../Utils/dates';
import { RedoOutlined } from '@ant-design/icons';

const RangePicker = DatePicker.RangePicker;

const { Option } = Select;

const b = b_.lock('JobFilters');

function JobFilters({ className, filters, onFilter, withCodeFilter = true }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);


  const handleResetFilters = useCallback(() => {
    for (var prop in filters) delete filters[prop];
    setCurFilters([]);
    onFilter()
  }, [filters, onFilter])

  return (
  <>
  <Row justify={'space-between'}>
    <Col >
      <span className={b('label')}>JobId</span>
      <Input value={curFilters.jobId ? curFilters.jobId[0] : null}
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'jobId')}
      />
    </Col>

    {withCodeFilter && <Col >
      <span className={b('label')}>Code</span>
      <Input className={b('item')}
             value={curFilters.code ? curFilters.code[0] : null}
             onChange={e => handleChangeFilter(e.target.value, 'code')}
      />
    </Col>}

    <Col>
      <span className={b('label')}>Status</span>

      <Select
      value={curFilters.status ? curFilters.status[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'status')}
    >
      <Option value="Error">Error</Option>
      <Option value="Done">Done</Option>
      <Option value="New">New</Option>
    </Select>
    </Col>

    <Col >
      <span className={b('label')}>Dates</span>
      <RangePicker showTime
                   value={curFilters.Dates ? curFilters.Dates.map(unixToMoment) : [null, null]}
                   className={b('item')}
                   onChange={dates => handleChangeFilter(dates ? dates.map(momentToUnix) : null, 'Dates')}
      />
    </Col>
    </Row>
    <Row gutter={8} style={{marginBottom: '16px'}}>
    <Col>

  <span className={b('label')} />
      <Button
        type="primary"
        onClick={handleResetFilters}
        className={b('item')}
        icon={<RedoOutlined />}
      >
        Reset Filters
      </Button>
      </Col>
    <Col >
      <span className={b('label')} />
      <Button
          className={b('item')}
          type="primary"
          onClick={() => onFilter(curFilters)}>Filter</Button>
    </Col>
  </Row>
  </>
  )
}

export default JobFilters;
