import ApiService from "./ApiService";


export default {
    getArchiveAccount ({orderPath, ...params}) {
        const updatedParams = { ...params };
        if(orderPath) {
            updatedParams.order = orderPath.replace('end', '')
        }
        return ApiService.get('/archive_accounts', updatedParams)
    }
}
