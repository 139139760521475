import React, {useCallback, useEffect} from 'react';
import b_ from 'b_';
import Title from 'antd/es/typography/Title';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchDrivers} from '../../Reducers/driver';
import DriverTable from './DriverTable';
import CreateDriver from './CreateDriver';
import './styles.scss';

const b = b_.lock('Driver');

function Driver() {
  const {payload, error, isLoading} = useSelector(state => state.driver.drivers, shallowEqual);
  const dispatch = useDispatch();

  const fetch = useCallback(() => dispatch(fetchDrivers()), [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return <div>
    <Title className={b('title')} level={1}>
      Drivers
    </Title>
    <div className={b('buttonsBlock')}>
      <CreateDriver afterUpdate={fetch}/>
    </div>
    <DriverTable payload={payload}
                 error={error}
                 isLoading={isLoading}
                 fetch={fetch}
    />
  </div>;
}

export default Driver;
