import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import { message, Table } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import Expanded from '../../Accounts/AccountsTable/Expanded';
import UpdateProxies from "../../Proxies/ProxiesTable/EditProxies";
import UpdatePortProxies from "../../Proxies/ProxiesTable/EditPortProxies";
import UpdateCommentProxies from "../../Proxies/ProxiesTable/EditCommentProxies";
import {changeProxies} from "../../../Reducers/proxies";
import PrintProxiesState from "../../../Components/Prints/PrintProxiesStatus";
import {Link} from "react-router-dom";

const b = b_.lock('ProxyTable');

function ProxyTable({ data, isLoading, afterUpdate, className }) {
  const dispatch = useDispatch();

  const {payload: clients} = useSelector(state => state.clients.list);

  const loadings = useSelector(state => ({
    proxy: state.proxy.proxy.isLoading,
  }), shallowEqual);

    const fieldsChange = [
        "id",
        "type",
        "host",
        "port",
        "user",
        "password",
        "status",
        "comment"
    ];

  const updateProxies = useCallback((params) => {
    dispatch(changeProxies([params])).then(() => {
      message.success('Proxy has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update proxy');
    });
  }, [dispatch, afterUpdate]);

  const getColumnsTable = () => ([
          {
              dataIndex:"id",
              title:"ID",
              align: "center",
              defaultView: true,
              width: 50,
              render:id => <Link to={`proxy/${id}`}>{id}</Link>,
          },
          {
              dataIndex:"type",
              title:"Type",
              align: "center",
              defaultView: true,
              width: 150,
              render:(type, rec) => (<UpdateProxies
                  fieldsChange={fieldsChange}
                  updateProxies={updateProxies}
                  value={type}
                  title={"type"}
                  rec={rec}/>)
          },
          {
              dataIndex: "host",
              title: "Host",
              align: "center",
              width: 50,
              render: (host, rec) => (<UpdateProxies
                  fieldsChange={fieldsChange}
                  updateProxies={updateProxies}
                  value={host}
                  title={"host"}
                  rec={rec}/>)
          },
          {
              dataIndex:"port",
              title:"Port",
              defaultView: true,
              align:"center",
              width: 70,
              render:(port, rec) => (<UpdatePortProxies
                  fieldsChange={fieldsChange}
                  updateProxies={updateProxies}
                  value={port}
                  title={"port"}
                  rec={rec}/>)
          },
          {
              dataIndex:"user",
              title:"User",
              align: "center",
              defaultView: true,
              width: 70,
              render:(user, rec) => (<UpdateProxies
                  fieldsChange={fieldsChange}
                  updateProxies={updateProxies}
                  value={user}
                  title={"user"}
                  rec={rec}/>)
          },
          {
              dataIndex:"password",
              title:"Password",
              align: "center",
              defaultView: true,
              width: 70,
              render:(status, rec) => (<UpdateProxies
                  fieldsChange={fieldsChange}
                  updateProxies={updateProxies}
                  value={status}
                  title={"password"}
                  rec={rec}/>)
          },
          {
              dataIndex:"status",
              title:"Status",
              align: "center",
              defaultView: true,
              width: 70,
              render:(status, rec) => (<PrintProxiesState
                  fieldsChange={fieldsChange}
                  handleSave={updateProxies}
                  status={status}
                  field={"status"}
                  rec={rec}/>)
          },
          {
              dataIndex:"workers",
              title:"Workers",
              align: "center",
              defaultView: true,
              width: 30,
              render:(workers, rec) =>
                  <div>
                      {workers.length ? workers.map(e => <div>{e}</div>) : "Unknown"}
                  </div>
          },
          {
              dataIndex:"comment",
              title:"Comment",
              defaultView: true,
              align:"center",
              width: 70,
              render:(comment, rec) => (<UpdateCommentProxies
                  fieldsChange={fieldsChange}
                  updateProxies={updateProxies}
                  value={comment || "comment"}
                  title={"comment"}
                  rec={rec}/>),
          },
      ])

  const [columns, setColumns] = useState(getColumnsTable())

  useEffect( () => {
       setColumns([...getColumnsTable()])
  },[data, loadings.proxy, isLoading, clients])

  const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

  return <Table bordered
                className={`${b()} ${className}`}
                loading={loader}
                size="small"
                scroll={{ x: 'max-content' }}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowClassName={() => b('lock')}
                rowKey="id"
                expandable={{
                  expandedRowRender: record => <Expanded record={record} />,
                }}
  >

  </Table>;
}

export default ProxyTable;
