import sha256 from '../Utils/sha256';
import base64url from 'base64url';
import { Buffer } from 'buffer';
 
// @ts-ignore
window.Buffer = Buffer;
const encoder = new TextEncoder();
const UPDATE_TOKEN_TIME = 5 * 60 * 1000;

class TokenService {
  constructor(lsKey) {
    this.cbs = [];
    this.lsKey = lsKey;
  }

  hasToken() {
    return !!this.getToken();
  }

  setToken(token, accessToken) {
    if (!token) {
      this.removeToken();
    } else {
      if(this.getToken() !== token) {
        localStorage.setItem(this.lsKey, token);
        // localStorage.setItem(this.lsKey + accessPrefix, accessToken);
        this.fireChangeEvent(token);
      }
    }
  }

  getToken() {
    return localStorage.getItem(this.lsKey + '_id_token') || null;
  }

  getAuth() {
    const exp = +localStorage.getItem(this.lsKey + '_expires_in');

    if (!exp) {
      throw { message: 'No expire time', code: 1 };
    }

    if (exp - new Date().getTime() < UPDATE_TOKEN_TIME) {
      throw { message: 'Need to update token', code: 2 }
    }

    return `${localStorage.getItem(this.lsKey + '_token_type')} ${localStorage.getItem(this.lsKey + '_id_token')}`
  }

  removeToken() {
    localStorage.removeItem(this.lsKey + '_id_token');
    localStorage.removeItem(this.lsKey + '_access_token');
    localStorage.removeItem(this.lsKey + '_refresh_token');
    localStorage.removeItem(this.lsKey + '_expires_in');
    localStorage.removeItem(this.lsKey + '_token_type');
    this.fireChangeEvent(null);
  }

  fireChangeEvent(token) {
    this.cbs.forEach(cb => cb(!!token));
  }

  subscribe(cb) {
    this.cbs.push(cb);
  }

  async generateAndSaveSha256() {
    const text = Math.random().toString(36).substring(2);
    const baseSha = await sha256(encoder.encode(text));
    localStorage.setItem(this.lsKey + '_challenge', text);

    return base64url(baseSha);
  }

  getChallenge() {
    return localStorage.getItem(this.lsKey + '_challenge');
  }

  getRefreshToken() {
    return localStorage.getItem(this.lsKey + '_refresh_token') || null;
  }

  setTokens({ id_token, access_token, refresh_token, expires_in, token_type }) {
    localStorage.setItem(this.lsKey + '_id_token', id_token);
    localStorage.setItem(this.lsKey + '_access_token', access_token);
    refresh_token && localStorage.setItem(this.lsKey + '_refresh_token', refresh_token);
    localStorage.setItem(this.lsKey + '_expires_in', `${new Date().getTime() + (expires_in * 1000)}`);
    localStorage.setItem(this.lsKey + '_token_type', token_type);
    this.fireChangeEvent(id_token);
  }
}

export default new TokenService('b-dash');
