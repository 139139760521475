import React, { useCallback } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProxyTable from './ProxyTable';
import './styles.scss';
import useTableState from '../../Utils/useTableState';
import {fetchProxy} from "../../Reducers/proxy";

const b = b_.lock('ProxyPage');

function ProxyPage() {
  const dispatch = useDispatch();
  const params = useParams();

  const fetchJobs = useCallback((data) => fetchProxy(params.id), []);
  const { fetch, ...props } = useTableState(
    state => state.account.jobs,
    fetchJobs,
  );

  const {
    payload,
    isLoading,
  } = useSelector(state => state.proxy.proxy, shallowEqual);

  const afterUpdate = useCallback(() => {
    dispatch(fetchProxy(params.id));
  }, [fetch, dispatch, params]);

  return <div>
    <Title className="mb">Proxy-details</Title>
    <ProxyTable isLoading={isLoading}
                  data={(payload && [payload]) || null}
                  afterUpdate={afterUpdate}
                  className="mb"
    />
  </div>;
}

export default ProxyPage;
