import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import ProxiesService from '../Services/ProxiesService';

export const fetchProxy = createAction('proxy/get', (id) => ({
  payload: ProxiesService.fetchProxy(id),
}));


const initState = {
  proxy: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchProxy, 'proxy')
});
