import React, {useCallback} from 'react';
import {Button, Drawer, Form, Popover, Select} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import { useDispatch } from 'react-redux';
import {UsergroupAddOutlined} from "@ant-design/icons";

function AccountsChosen({ accountIds,  addAccountChosen, accountsGroups, groups }) {
    const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
    const dispatch = useDispatch();

    const handleSuccess = useCallback(({ titleGroup, ...p }) => {
        addAccountChosen({ accountIds: [accountIds], groups: titleGroup });
        close();
    }, [dispatch, accountIds, close]);

    return <>
        <Popover content={"add/delete group"}>
            <UsergroupAddOutlined onClick={open}/>
        </Popover>
        <Drawer onClose={close}
                visible={isOpen}
                title="Change group"
                placement="right"
                width={600}
        >
            <Form layout="vertical" onFinish={handleSuccess}>
                <Form.Item
                    rules={[
                        { required: false},
                    ]}
                    name="titleGroup"
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        defaultValue={accountsGroups}
                    >
                        {groups && groups.length && groups.map(e =>  <Select.Option
                            value={e}
                            key={e}>{e}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        >
                        change account groups
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    </>;
};

export default AccountsChosen;
