import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createStore from './Boot/createStore';
import App from './Boot/App';
import TokenService from './Services/TokenService';
import { authSetIsAuthenticated } from './Reducers/auth';
import './App.scss';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

const store = createStore({});

if (TokenService.hasToken()) {
  store.dispatch(authSetIsAuthenticated(true));
}

TokenService.subscribe(function (isAuthenticated) {
  store.dispatch(authSetIsAuthenticated(isAuthenticated));
});

const AppRouter = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  )
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AppRouter />);
