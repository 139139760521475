import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { cancelJobsAccount, fetchAccount, fetchJobsAccount } from '../../Reducers/account';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccountTable from './AccountTable';
import './styles.scss';
import useTableState from '../../Utils/useTableState';
import { Button, message } from 'antd';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import { fetchAuxAccount } from '../../Reducers/accounts';
import JobFilters from '../../Components/JobFilters';
import {fetchClientsList} from "../../Reducers/clients";
import JobsTableWrapper from '../../Components/JobsTableWrapper';
import AccountsTable from '../Accounts/AccountsTable';

const b = b_.lock('AccountPage');

function AccountPage() {
  const dispatch = useDispatch();
  const params = useParams();

  const fetchJobs = useCallback((data) => fetchJobsAccount({ ...data, account_id: params.id }), []);
  const { fetch, ...props } = useTableState(
    state => state.account.jobs,
    fetchJobs,
  );

  const [selectedRow, setSelectedRow] = useState([]);
  const cancelLoading = useSelector(state => state.account.cancelJobs.isLoading, shallowEqual);

  const {
    payload,
    isLoading,
  } = useSelector(state => state.account.account, shallowEqual);

  useEffect(() => {
    dispatch(fetchAccount(params.id));
    // dispatch(fetchAuxAccount());
    // dispatch(fetchClientsList());
  }, [dispatch]);

  const cancelJobs = useCallback(() => {
    dispatch(cancelJobsAccount(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  const afterUpdate = useCallback(() => {
    dispatch(fetchAccount(params.id));
    fetch();
  }, [fetch, dispatch, params]);

  const jobsList = props.list && props.list

  return <div>
    <Title className="mb">Account-details - {(payload && payload.login) || params.id}</Title>
    <AccountsTable 
                  isLoading={isLoading}
                  list={(payload && [payload]) || null}
                  afterUpdate={afterUpdate}
                  className="mb"
                  sorter={false}
    />
    <Title className={`mb ${b('title')}`}>
      Jobs
    </Title>
    <JobsTableWrapper
        {...props}
        jobTableType='acc_jobs_table'
        jobsList={jobsList}
        loading={isLoading}
        afterUpdate={fetch}
      />
  </div>;
}

export default AccountPage;
