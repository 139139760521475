import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import {useDispatch} from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import './styles.scss';
import { fetchConfigs } from '../../Reducers/config';
import {getArchiveAccounts} from "../../Reducers/archiveAccounts";
import ArchiveAccountsTable from "./ArchiveAccountsTable";

const b = b_.lock('Accounts');

function ArchiveAccounts() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    onSearch,
    query,
  } = useTableState(state => state.archiveAccounts.archiveList, getArchiveAccounts);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const [selectedRow, setSelectedRow] = useState([]);

  return <div className={b()}>
    <Title className="mb">
        Archive Accounts
    </Title>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <Input.Search defaultValue={query} onSearch={onSearch} className={["mb", b("input_search")]} size="large" />
    <ArchiveAccountsTable selected={selectedRow}
                   setSelected={setSelectedRow}
                   afterUpdate={fetch}
                   archiveList={list.list}
                   pagination={pagination}
                   onChange={onChange}
                   onFilter={onFilter}
                   isLoading={isLoading}
                   filters={filters}
                   sorter={sort}
    />
  </div>;
}

export default ArchiveAccounts;
