import React, { useCallback } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { disableAccounts } from '../../../Reducers/accounts';
import {CloseOutlined} from "@ant-design/icons";

function DisableButton({ className, selected, afterSuccess }) {
  const { isLoading } = useSelector(state => state.accounts.disableAccounts, shallowEqual);
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    dispatch(disableAccounts({ action: 'disable', accountIds: selected }))
      .then(() => {
      afterSuccess();
      message.success('Accounts has been disabled!');
    })
      .catch(() => {
        message.error('Can\'t disabled accounts!');
      });
  }, [dispatch, selected, afterSuccess]);

  return (<Popconfirm title={`Are you sure disable ${selected.length} accounts?`}
                      onConfirm={submit}
                      okText="Yes"
                      cancelText="No"
  >
    <Button
        loading={isLoading}
        type="dashed"
        color="secondary"
        size="medium"
        variant="contained"
        startIcon={<CloseOutlined />}
        disabled={!selected.length}>
      Disable {selected.length} accounts
    </Button>
  </Popconfirm>);
}

export default DisableButton;
