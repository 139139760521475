import React, {useCallback, useState} from 'react';
import b_ from 'b_';
import {Button, message} from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import {cancelPurchaseJobs, fetchPurchaseJobs, downloadInvoices, readTracking} from '../../Reducers/purchaseJobs';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import JobFilters from '../../Components/JobFilters';
import './styles.scss';
import ErrorBlock from '../../Components/ErrorBlock';
import UploadOrders from './UploadOrders';
import {CloseCircleOutlined, DownloadOutlined, ReadOutlined} from "@ant-design/icons/lib/icons";
import useStorageState from "../../Utils/useStorageState";
import JobsTableWrapper from '../../Components/JobsTableWrapper';

const b = b_.lock('PurchaseJobs');

function PurchaseJobs() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTableState(state => state.purchaseJobs.list, fetchPurchaseJobs);
  const [selectedRows, setSelectedRows] = useState([]);
  const [accountsGroups] = useStorageState('accountsChosenResellM');
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.purchaseJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelPurchaseJobs(selectedRows)).then(() => {
      message.success('Jobs have been canceled!');
      setSelectedRows([]);
      fetch();
    });
  }, [dispatch, selectedRows, fetch, setSelectedRows]);

  const invoices = useCallback(() => {
    dispatch(downloadInvoices(selectedRows)).then(() => {
      message.success('Invoice jobs have been created for download!');
      setSelectedRows([]);
      fetch();
    });
  }, [dispatch, selectedRows, fetch, setSelectedRows]);

  const tracking = useCallback(() => {
    dispatch(readTracking(selectedRows)).then(() => {
      message.success('Tracking jobs have been created for download!');
      setSelectedRows([]);
      fetch();
    });
  }, [dispatch, selectedRows, fetch, setSelectedRows]);

  return <div className={b()} >
    <Title className={`mb ${b('title')}`} style={{flexWrap: 'wrap'}}>
      Purchase Jobs
    </Title>
    <div className={b('buttonsBlock')}>
        <UploadOrders accountsGroups={accountsGroups} afterSuccess={fetch} className="mr-small"/>
      </div>
    {error && <ErrorBlock className="mb" message={error.message || error}/>}
    <JobFilters filters={filters} onFilter={onFilter} className="mb"/>
    <JobsTableWrapper
        jobTableType='purchase_jobs_table'
        jobsList={list}
        onChange={onChange}
        pagination={pagination}
        isLoading={isLoading}
        fetch={fetch}
        onFilter={onFilter}
        filters={filters}
      />
  </div>;
}

export default PurchaseJobs;
