import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Button, Col, DatePicker, Input, Row } from 'antd';
import './styles.scss';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import { frc } from 'front-end-common';

const RangePicker = DatePicker.RangePicker;

const { SelectItemToFilter } = frc.components

const b = b_.lock('AccountsFilters');

function AccountsFilters({ className, filters, onFilter }) {
  const [curFilters, setCurFilters] = useState(filters);

  console.log('filters', filters)
  console.log('curFilters', curFilters)

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  // const handleResetFilter = useCallback(() => {
  //   setCurFilters({ ...curFilters, [field]: preparedValue });
  // }, [])

  const onResetFilters = () => {
    console.log('FILTERSVAR', filters)
    for (var key in filters) delete filters[key]
    setCurFilters({})
    onFilter(filters)
  }

  const elemsToFilter = [
    {
      title: "Currency",
      value_to_filter: "currency",
      options_to_filter: [
        {value: "EUR", label: "EUR"}, 
        {value: "USD", label: "USD"},
        {value: "RUB", label: "RUB"}
       ],
      style: { width: '150px'}
    },
    {
      title: "Status",
      value_to_filter: "status",
      options_to_filter: [
        {value: "Enabled", label: "Enabled"}, 
        {value: "Disabled", label: "Disabled"},
        {value: "Invalid", label: "Invalid"}, 
        {value: "TempInvalid", label: "TempInvalid"},
        {value: "Processing", label: "Processing"}],
      style: { width: '150px'}
    },
    {
      title: "Phase",
      value_to_filter: "phase",
      options_to_filter: [{value: "Redeem", label: "Redeem"}, {value: "Purchase", label: "Purchase"}],
      style: { width: '150px'}
    },
    {
      title: "Result Code",
      value_to_filter: "resultCode",
      options_to_filter: [
        {value: "OK", label: "OK"}, 
        {value: "ERROR", label: "ERROR"},
        {value: "ACCOUNT_LOCKED", label: "ACCOUNT_LOCKED"}
       ],
       style: { width: '250px'}
    }
  ]

  return (
  <>
  <Row justify={'space-between'} className={`${className} ${b()}`}>
  {elemsToFilter.map(({ value_to_filter, options_to_filter, title, style }) => (
              <Col  key={title} style={style}>
              <span className={b('label')}>{title}</span>
              <SelectItemToFilter 
                  className={b('item')}
                  value={curFilters?.[value_to_filter] ? curFilters?.[value_to_filter]?.[0] : null}
                  onChange={(v) => handleChangeFilter(v, value_to_filter) }
                  options={options_to_filter}
                />
                </Col>
            ))}
  </Row>
  <Row gutter={8} style={{marginBottom: '16px'}}>
  <Col>
  <span className={b('label')} />
      <Button
        type="primary"
        onClick={onResetFilters}
        className={b('item')}
        icon={<RedoOutlined />}
      >
        Reset Filters
      </Button>
      </Col>
      <Col >
      <span className={b('label')} />
      <Button
        type="primary"
        onClick={() => onFilter(curFilters)}
        className={b('item')}
        icon={<FilterOutlined />}
      >
        Filter
      </Button>
      
      </Col>
      </Row>
      </>
  )
}

export default AccountsFilters;
