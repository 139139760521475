import {createAction, createReducer} from "@reduxjs/toolkit";
import {getDefaultHandler, getDefaultState} from "../Utils/newReducerTools";
import ArchiveAccountsService from "../Services/ArchiveAccountsService";


export const getArchiveAccounts = createAction('/archive/accounts', (params) => ({
    payload: ArchiveAccountsService.getArchiveAccount(params)
}))

const initialState = {
    archiveList: getDefaultState()
}

export default  createReducer(initialState, {
    ...getDefaultHandler(getArchiveAccounts, 'archiveList')
})
