import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import { Input, message, Select, Table } from 'antd';
import EditableField from '../../../Components/EditableField';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import TextArea from 'antd/lib/input/TextArea';
import ChangePassword from '../../Accounts/ChangePassword';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  changeStatusAccount,
} from '../../../Reducers/account';
import ChangeToken from '../../Accounts/ChangeToken';
import './styles.scss';
import Expanded from '../../Accounts/AccountsTable/Expanded';
import EnabledJobs from '../../../Components/EnabledJobs';
import {fetchAccountsGroups, updateAccounts} from "../../../Reducers/accounts";
import CurrencySelector from "../../Accounts/Selectors/CurrencySelector";
import AccountsChosen from "../../Accounts/AccountsChosen";

const b = b_.lock('AccountTable');

function AccountTable({ data, isLoading, afterUpdate, className }) {
  const dispatch = useDispatch();
  const fieldsChange = [
      "login",
      "phase",
      "authToken",
      "currency",
      "comment",
      "balLimit",
      "driver",
      "enabledJobs",
  ]

  const {payload: clients} = useSelector(state => state.clients.list);
  const groups = useSelector(state => (state.accounts.accountsGroups.payload));

  useEffect(() => {
      !groups && dispatch(fetchAccountsGroups());
  }, [])

  const loadings = useSelector(state => ({
    status: state.account.changeStatus.isLoading,
    updateAccounts: state.accounts.updateAccounts.isLoading,
  }), shallowEqual);


  const updateAccount = useCallback((data) => {
      dispatch(updateAccounts(data)).then(() => {
          message.success('account has been updated!');
          afterUpdate();
      }).catch(error => {
          console.log(error);
          message.error('Can\'t update Enabled Jobs.');
      });
  }, [dispatch, afterUpdate]);

  const updateAccountState = useCallback((data) => {
      dispatch(changeStatusAccount(data)).then(() => {
          message.success('account state has been updated!');
          afterUpdate();
      }).catch(error => {
          console.log(error);
          message.error('Can\'t update state.');
      });
  }, [dispatch, afterUpdate]);

    const updateAccountGroups = useCallback((data) => {
        console.log(data);
    }, [dispatch, afterUpdate]);

  const GetColumnsTable = () => {
      return [{
          dataIndex: "id",
          title: "ID"
      },
      {
          dataIndex: "login",
          title: "Login",
          render:(login, rec) => (
              <EditableField handleSave={updateAccount}
                             title="Update Login"
                             field={"login"}
                             rec={rec}
                             withTitle
                             titlePopover="Edit Login"
                             isLoading={loadings.login || isLoading}
                             iconClassName={b('edit')}
                             initialValue={login}
                             changeBlock={({onChange, ...props}) => (
                                 <Input {...props}
                                        size="small"
                                        onChange={e => onChange(e.target.value)}/>
                             )}>
                  {login}
              </EditableField>
          ),
      },
      {
          dataIndex: "status",
          title: "Status",
          width: "120px",
          render: (status, rec) => (<PrintAccountState
              fieldsChange={fieldsChange}
              status={status}
              rec={rec}
              handleSave={updateAccountState}/>),
      },
      {
          dataIndex:"enabledJobs",
          title:"Enabled Jobs",
          render:(enabledJobs, rec) => (
              <EnabledJobs
                  rec={rec}
                  enabledJobs={enabledJobs}
                  field={"enabledJobs"}
                  iconClassName={b('edit')}
                  handleSave={updateAccount}
              />),
      },
      {
          dataIndex:"currency",
          title:"Currency",
          align: "center",
          defaultView: true,
          width: 30,
          render:(value, rec) =>
              <EditableField handleSave={updateAccount}
                             field={"currency"}
                             rec={rec}
                             title="Update Currency"
                             titlePopover="Edit Currency"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={isLoading}
                             initialValue={value}
                             changeBlock={(props) => (
                                 <CurrencySelector {...props}
                                                   size="small"
                                                   className="w100"
                                 />
                             )}>
                  {value}
              </EditableField>
      },
      {
          dataIndex : "balance",
          title : "Balance",
          align : "right",
          render : value => <PrintPrice value={value}/>,
      },
      {
          dataIndex:"balLimit",
          title:"Limit",
          align:"right",
          render:(value, rec) => (<EditableField
              handleSave={updateAccount}
              field={"balLimit"}
              rec={rec}
              title="Update BalLimit"
              titlePopover="Edit BalLimit"
              iconClassName={b('edit')}
              withTitle
              isLoading={loadings.balLimit || isLoading}
              initialValue={value}
              changeBlock={({onChange, ...props}) => (
                  <Input {...props}
                         size="small"
                         onChange={e => onChange(e.target.value)}/>
              )}>
              <PrintPrice value={value}/>
          </EditableField>),
      },
      {
          dataIndex : "driver",
          title : "Driver",
          align : "right",
          render : (value, rec) => (<EditableField
          handleSave={updateAccount}
          title="Update Driver"
          rec={rec}
          field={"driver"}
          titlePopover="Edit Driver"
          isLoading={loadings.driver || isLoading}
          iconClassName={b('edit')}
          withTitle
          initialValue={value}
          changeBlock={({onChange, ...props}) => (
              <Input {...props}
                     size="small"
                     type="number"
                     onChange={e => onChange(e.target.value)}/>
          )}>
          {value}
      </EditableField>),
      },
      {
          dataIndex: "comment",
          title: "Comment",
          render: (value, rec)  => (
          <EditableField handleSave={updateAccount}
                         rec={rec}
                         field={"comment"}
                         title="Update Comment"
                         titlePopover="Edit Comment"
                         isLoading={loadings.comment || isLoading}
                         iconClassName={b('edit')}
                         withTitle
                         initialValue={value}
                         changeBlock={({onChange, ...props}) => (
                             <TextArea {...props}
                                       size="small"
                                       onChange={e => onChange(e.target.value)}/>)}>
              {value}
          </EditableField>),
      },
      {
          dataIndex : "resultCode",
          title : "Result Code",
      },
      {
          dataIndex : "id",
          title : "Actions",
          render : (id, rec) => <div className={b('action')}>
          <div className={b('icon')}>
              <div>
                  <AccountsChosen accountIds={id}
                                  accountsGroups={rec.groups}
                                  groups={groups}
                                  addAccountChosen={updateAccountGroups}
                  />
                  <ChangePassword className="mr-small" accountIds={id}/>
              </div>
          </div>
      </div>
      } ]

  }
  const [columns, setColumns] = useState(GetColumnsTable())
  const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

  useEffect( () => {
      setColumns([...GetColumnsTable()])
  },[data, loader, clients])

  return <Table bordered
                className={`${b()} ${className}`}
                loading={loader}
                size="small"
                scroll={{ x: 'max-content' }}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowClassName={() => b('lock')}
                rowKey="id"
                expandable={{
                  expandedRowRender: record => <Expanded record={record} />,
                }}
  >

  </Table>;
}

export default AccountTable;
