import React from 'react';
import { Progress, Tooltip } from 'antd';
import './styles.scss';

const CircularProgressWithData = ({ balance, percent, balanceLimitPercent }) => {

  const limitToFixed = balanceLimitPercent.toFixed(1);
  const balanceToLimit = (limitToFixed - balance).toFixed(1)

  return (
    <Tooltip title={percent >= 100 ? `A limit of ${limitToFixed} was reached!` : `${balanceToLimit} left to limit`}>
        <div className="circular-progress-container">
          <div className="circular-progress-text">{balance}</div>
          <Progress
            type="circle"
            percent={percent}
            status={percent >= 100 ? "exception" : ""}
            showInfo={false}
            // strokeColor={{
            //   '0%': '#108ee9',
            //   '100%': '#87d068',
            // }}
          />
        </div>
    </Tooltip>
  );
};

export default CircularProgressWithData;
