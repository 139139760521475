import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { fetchAccounts } from '../../Reducers/accounts';
import AccountsTable from './AccountsTable';
import CreateAccount from './CreateAccount';
import UpdateAccounts from './UpdateAccounts';
import AccountGroupBalance from './AccountGroupBalanceTable';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import DisableButton from './DisableButton';
import EnableButton from './EnableButton';
import ErrorBlock from '../../Components/ErrorBlock';
import './styles.scss';
import { fetchConfigs } from '../../Reducers/config';
import AccountsFilters from '../../Components/AccountsFilters';

const b = b_.lock('Accounts');

function Accounts() {

const {
  list,
  pagination,
  onChange,
  onFilter,
  isLoading,
  fetch,
  filters,
  error,
  query,
  onSearch
} = useTableState(state => state.accounts.list, fetchAccounts);

  const dispatch = useDispatch();
 
  console.log("list", list)

  const [selectedRow, setSelectedRow] = useState([]);
  
  return <div className={b()}>
    <Title className="mb">
      Accounts
    </Title>
    <AccountGroupBalance className="mb" />
    <div className={b('accountBlock')} style={{flexWrap: 'wrap'}}>
      <CreateAccount className="mr" afterSuccess={fetch} />
      <UpdateAccounts className="mr" afterSuccess={fetch} selected={selectedRow} />
      <DisableButton className="mr" selected={selectedRow} afterSuccess={fetch} />
      <EnableButton selected={selectedRow} afterSuccess={fetch} />
    </div>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <AccountsFilters filters={filters} onFilter={onFilter} className="mb"  />
    <Input.Search defaultValue={query} onSearch={onSearch} className={["mb", b("input_search")]} size="large" />
    <AccountsTable selected={selectedRow}
                   setSelected={setSelectedRow}
                   afterUpdate={fetch}
                   list={list.list}
                   pagination={pagination}
                   onChange={onChange}
                   onFilter={onFilter}
                   isLoading={isLoading}
                   filters={filters}
                   sorter={true}
    />
  </div>;
}

export default Accounts;
