import React, { useCallback, useEffect } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { List, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import { fetchConfigs, updateConfig } from '../../Reducers/config';
import ConfigItem from './ConfigItem';
import './styles.scss';

import { frc } from 'front-end-common';

const { Configs, DisplayErrorBlock } = frc.components

const b = b_.lock('Config');

function Config() {
  const { data: { payload, isLoading, error }, update } = useSelector(state => state.config, shallowEqual);
  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleUpdateConfig = 
    (value, data) => {
      dispatch(updateConfig({ name: data, value })).then(() => {
        message.success(`${data} has been updated!`);
        fetch();
      })
      .catch((error) =>{
        message.error(`Error update config: ${error}`)
      })
    }

  return <>
    <Title className="mb">Config</Title>
    {error && <DisplayErrorBlock className="mb" message={error.message} />}
    <Configs dataSource={payload} loader={isLoading || update.isLoading } fetch={fetch} handleUpdateConfig={handleUpdateConfig} />
  </>;
}

export default Config;
