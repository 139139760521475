import React, { useCallback, useMemo } from 'react';
import b_ from 'b_';
import { Button, Drawer, Form, Input, message } from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import CurrencySelector from '../Selectors/CurrencySelector';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { createAccount } from '../../../Reducers/accounts';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import {LoadingOutlined} from "@ant-design/icons";
import GroupSelector from "../../../Components/GroupSelector";

const b = b_.lock('CreateAccount');

function CreateAccount({ className, afterSuccess }) {
  const dispatch = useDispatch();
  const aux = useSelector(state => state.accounts.aux.payload, shallowEqual);
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);

  const defaultValues = useMemo(() => ({
    balLimit: aux ? aux.limitDefault : undefined,
    purchLimit: aux ? aux.purchLimitDefault : undefined,
  }), [aux]);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const handleSuccess = useCallback((data) => {
    dispatch(createAccount(data)).then(() => {
      close();
      afterSuccess();
      message.success('Account has been added!');
    });
  }, [close, afterSuccess, dispatch]);

  const error = useSelector(state => state.accounts.create.error, shallowEqual);

  return (
    <>
      <Button
          type="primary"
          className={className}
          icon={<PlusOutlined />}
          onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Account" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            rules={[
              { required: true, message: 'Please input account login.' },
            ]}
            label="Login:"
            name="login"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input password.' },
            ]}
            label="Password:"
            name="password"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select currency.' },
            ]}
            label="Currency:"
            name="currency"
          >
            <CurrencySelector />
          </Form.Item>
          <Form.Item
            rules={[{required: true, type: 'number', min: 0, max: 250 , message: "Limit must be between 0 and 250"}]}
            label="Balance Limit:(250)"
            name="balLimit"
            initialValue={defaultValues.balLimit}
            normalize={v => +v}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item label={"Secret:"} name={"secret"}>
              <Input/>
          </Form.Item>
          <Form.Item label={"Serial number:"} name={"serialNum"}>
              <Input/>
          </Form.Item>
          <Form.Item label={"Restore Code:"} name={"restoreCode"}>
              <Input/>
          </Form.Item>
          <Form.Item rules={[{required: true}]} label={"Group:"} name={"group"}>
              <GroupSelector />
          </Form.Item>
          <Form.Item
            label="Comment:"
            name="comment"
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
            <Button
                type="primary"
                icon={isLoading && <LoadingOutlined />}
                htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateAccount;
