import { ChromeFilled } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import React from 'react'

const UseDefaultChromeUserButton = ({ id, defaultUserData }) => {
  return (
    <Popconfirm title={`Are you sure to use default Chrome user profile?`}
                          onConfirm={() => defaultUserData(id)}
                          okText="Yes"
                          cancelText="No"
                          placement="topLeft"
      >
    <Popover placement={"topLeft"} content={"Use default Chrome user profile"}>
        <ChromeFilled />
    </Popover>
    </Popconfirm>
    
  )
}

export default UseDefaultChromeUserButton
