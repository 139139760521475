import React, {useCallback, useEffect, useState} from "react";
import {Button, Modal, Popover} from "antd";
import { useDispatch } from "react-redux";
import useBoolean from "../../Utils/useBoolean";
import GroupSelector from "../GroupSelector";

function ProxiesUpdateGroups({
  proxyGroups,
  handleSave,
  fieldsChange,
  title,
  rec,
}) {
  const dispatch = useDispatch();
  const [newGroupsProxy, changeGroups] = useState(proxyGroups || []);
  const { setValue: changeVisible, value: visible, setFalse: falseVisible} = useBoolean(false);

  const handleSuccess = useCallback(() => {
    const params = {
      id: rec.id,
    };

    fieldsChange.forEach((e) => (params[e] = rec[e] || ""));
    params[title] = newGroupsProxy;

    handleSave(params);
    falseVisible()
  }, [dispatch, newGroupsProxy]);

  useEffect(() => {
      changeGroups(proxyGroups)
  }, [proxyGroups])

  const warningChangeGroup = () => {
      falseVisible()
      Modal.warning({
          title: 'Change group proxy',
          content: 'All accounts uses this proxy will take another proxy after change',
          onOk: handleSuccess
      })
  }

  return (
    <Popover
      trigger={"click"}
      title={"Edit groups proxy"}
      visible={visible}
      onVisibleChange={changeVisible}
      content={() => (
        <div style={{ display: "flex", width: "400px" }}>
            <GroupSelector
                value={newGroupsProxy}
                className={"mr"}
                onChange={changeGroups}/>
          <Button
            color="primary"
            variant="contained"
            onClick={warningChangeGroup}
            disabled={proxyGroups === newGroupsProxy}
            style={proxyGroups !== newGroupsProxy ? { backgroundColor: "#1976d2" } : {}}
          >
            save
          </Button>
        </div>
      )}
    >
      {proxyGroups ? proxyGroups : "add group"}
    </Popover>
  );
}

export default ProxiesUpdateGroups;
