import React, { useCallback } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { enableAccounts } from '../../../Reducers/accounts';
import {CheckOutlined} from "@ant-design/icons";

function EnableButton({ className, selected, afterSuccess }) {
  const { isLoading } = useSelector(state => state.accounts.enableAccounts, shallowEqual);
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    dispatch(enableAccounts({ action: 'enable', accountIds: selected }))
      .then(() => {
      afterSuccess();
      message.success('Accounts has been enabled!');
    })
      .catch(() => {
        message.error('Can\'t enabled accounts!');
      });
  }, [dispatch, selected, afterSuccess]);

  return (<Popconfirm title={`Are you sure enabled ${selected.length} accounts?`}
                      onConfirm={submit}
                      okText="Yes"
                      cancelText="No"
  >
    <Button
        loading={isLoading}
        className={className}
        type="dashed"
        color="primary"
        startIcon={<CheckOutlined />}
        style={ selected.length ? {backgroundColor: "#4caf50"} : {}}
        size="medium"
        variant="contained"
        disabled={!selected.length}
    >
      Enable {selected.length} accounts
    </Button>
  </Popconfirm>);
}

export default EnableButton;
