import React, { useCallback, useReducer, useState } from 'react';
import b_ from 'b_';
import { Button, Checkbox, Drawer, Form, Input, message, Popconfirm } from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import CountrySelector from '../Selectors/CountrySelector';
import CurrencySelector from '../Selectors/CurrencySelector';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { updateAccounts } from '../../../Reducers/accounts';
import EditFilled from '@ant-design/icons/lib/icons/EditFilled';
import {LoadingOutlined} from "@ant-design/icons";
import GroupSelector from "../../../Components/GroupSelector";

const b = b_.lock('CreateAccount');

// const useStyles = makeStyles((theme) => ({
//     button: {
//         marginRight: 8,
//         marginLeft: 8,
//     },
// }));

function UpdateAccounts({ className, afterSuccess, selected }) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(state => state.accounts.updateAccounts.isLoading, shallowEqual);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const [resetFields, setResetFields] = useState([]);

  const changeResetFields = useCallback((field) => {
    if (resetFields.includes(field)) {
      return setResetFields(resetFields.filter(v => v !== field));
    }

    setResetFields([...resetFields, field]);
  }, [setResetFields, resetFields]);

  const handleSuccess = useCallback((formFields) => {
    const someExist = Object.keys(formFields).some(key => formFields[key]);

    if (!someExist && resetFields.length === 0) {
      return message.error('Please input one of field');
    }

    const fields = {...formFields, ...resetFields.reduce((acc, cur) => ({ ...acc, [cur]: cur === "enabledJobs" ? [] : '' }), {})};

    const data = { accountIds: selected, ...fields };
    dispatch(updateAccounts(data)).then(() => {
      close();
      afterSuccess();
      message.success('Accounts has been updated!');
      form.resetFields();
    });
  }, [close, afterSuccess, dispatch, selected, resetFields, form]);

  const error = useSelector(state => state.accounts.updateAccounts.error, shallowEqual);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  return (
    <>
      <Button
          disabled={!selected.length}
          size="medium"
          color="primary"
          variant="contained"
          startIcon={<EditFilled />}
          style={selected.length ? { backgroundColor: "#4383f2"}: {}}
          onClick={open}
      >
        Update {selected.length} accounts
      </Button>
      <Drawer visible={isOpen}
              title={`Update Accounts: ${selected.length}`}
              width={600}
              closable
              destroyOnClose
              placement="right"
              onClose={close}
              forceRender
      >
        {error && <ErrorBlock message={error.message} />}
        <Form onFieldsChange={forceUpdate} form={form} layout="vertical" className={b()} onFinish={handleSuccess}>

        <Form.Item
            label="Login:"
            name="login"
            hasFeedback={form.getFieldValue('login')}
            validateStatus="success"
        >
            <Input type="text" disabled={resetFields.includes('login')}/>
        </Form.Item>
            <Form.Item
                label="Password:"
                name="password"
            >
                <Input type="text" />
            </Form.Item>
            <Form.Item
                label="Currency:"
                name="currency"
            >
                <CurrencySelector />
            </Form.Item>
            <Form.Item
                rules={[{ type: 'number', min: 0, max: 250 , message: "Limit must be between 0 and 250"}]}
                label="Balance Limit:(250)"
                name="balLimit"
                normalize={v => +v}
            >
                <Input type="number" />
            </Form.Item>
            <Form.Item label={"Secret:"} name={"secret"}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Serial number:"} name={"serialNum"}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Restore Code:"} name={"restoreCode"}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Group:"} name={"groups"}>
                <GroupSelector mode={"multiple"}/>
            </Form.Item>
            <Form.Item label={<div>
                <span className="mr">Driver</span>
                <span className="mr-small">Reset field</span>
                <Checkbox checked={resetFields.includes('driver')}
                          onChange={() => changeResetFields('driver')}
                />
            </div>}
                            name="driver"
                            hasFeedback={form.getFieldValue('driver')}
                            validateStatus="success"
            >
                <Input disabled={resetFields.includes('driver')} />
            </Form.Item>
            <Form.Item
                label="Comment:"
                name="comment"
            >
                <TextArea />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    icon={isLoading && <LoadingOutlined />}
                    htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateAccounts;
