import ApiService from './ApiService';

export default {
  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = Array.isArray(filters[key]) ? filters[key].join(',') : filters[key];
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/accounts', updatedParams);
  },
  changeProxy(params) {
    const { id, proxy } = params;
    return ApiService.post('/account/update', { accountIds: [id], proxy });
  },
  changeEnabledJobs(params) {
    const { id, enabledJobs } = params;
    return ApiService.post('/account/update', { accountIds: [id], enabledJobs });
  },
  changeBalLimit(params) {
    const { id, balLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], balLimit });
  },
  changePurchLimit(params) {
    const { id, purchLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], purchLimit });
  },
  changeLogin(params) {
    const { id, login } = params;
    return ApiService.post('/account/update', { accountIds: [id], login });
  },
  changeCountry(params) {
    const { id, country } = params;
    return ApiService.post('/account/update', { accountIds: [id], country });
  },
  changeDriver(params) {
    const { id, driver } = params;
    return ApiService.post('/account/update', { accountIds: [id], driver });
  },
  changeClient(params) {
    const { id, client } = params;
    return ApiService.post('/account/update', { accountIds: [id], clientId: client });
  },
  changeComment(params) {
    const { id, comment } = params;
    return ApiService.post('/account/update', { accountIds: [id], comment });
  },
  createAccount(data) {
    return ApiService.put('/account', data);
  },
  updateAccounts(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.post('/account/update', updatedData);
  },
  changePassword(data) {
    return ApiService.post('/account/update', data);
  },
  changeToken(data) {
    return ApiService.post('/account/update', data);
  },
  changeStatus(data) {
    return ApiService.post('/job/no_arg', data);
  },
  enableWithheld(data) {
    const { id } = data;
    return ApiService.post('/job/no_arg', { action: 'enable-withheld', accountIds: [id] });
  },
  enablePending(data) {
    const { id } = data;
    return ApiService.post('/job/no_arg', { action: 'enable-pending', accountIds: [id] });
  },
  fetchAccountGroupBalance() {
    return ApiService.get('/group_balance').then((data = []) => {
      const all = data.reduce((acc, cur) => {
        acc.allBalEur += cur.allBalEur;
        acc.allCnt += cur.allCnt;
        acc.enBalEur += cur.enBalEur;
        acc.enCnt += cur.enCnt;
        acc.disBalEur += cur.disBalEur;
        acc.disCnt += cur.disCnt;
        acc.invBalEur += cur.invBalEur;
        acc.invCnt += cur.invCnt;
        return acc;
      }, {
        group: 'ALL',
        allBalEur: 0,
        allCnt: 0,
        enBalEur: 0,
        enCnt: 0,
        disBalEur: 0,
        disCnt: 0,
        invBalEur: 0,
        invCnt: 0,
        balToAll: 1,
      });

      const updData = data.map(v => ({ ...v, balToAll: v.allBal / all.allBal }));
      updData.push(all);

      return updData;
    });
  },
  fetchAux() {
    return ApiService.get('/accounts/aux');
  },
  fetchAccountsGroups() {
    return ApiService.get('/groups');
  },
  fetchAccount(id) {
    return ApiService.get('/account', { account_id: id });
  },
  getJobs(data) {
    return ApiService.get('/jobs', data);
  },
  disableAccounts(data) {
    return ApiService.post('/job/no_arg', data);
  },
  enableAccounts(data) {
    return ApiService.post('/job/no_arg', data);
  },

  removeUserDataDir(id) {
    return ApiService.get('/driver/remove_user_data_dir', { account_id: id });
  },
  defaultUserDataDir(id) {
    return ApiService.get('/driver/default_user_data_dir', { account_id: id });
  },

  accessAccountInfo(id) {
    return ApiService.get(`/account/access/${id}`);
  }

};
