import { Button, Popover} from 'antd';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import useBoolean from '../../Utils/useBoolean';
import LoadingBlock from '../LoadingBlock';
import {EditTwoTone} from '@ant-design/icons';

const EditableField = ({
                         children,
                         changeBlock,
                         handleSave,
                         initialValue,
                         iconClassName,
                         isLoading,
                         withTitle,
                         title,
                         iconStyle,
                         titlePopover,
                         popoverContentStyle,
                         rec,
                         field
                       }) => {
  const {value: showPopover, setFalse: closePopover, setValue: handleVisibleChange} = useBoolean(false);

  const inputRef = useRef(null);
  const [inputValue, handleChange] = useState(initialValue);

  const handleClosePopover = useCallback(() => {
    closePopover();
    handleChange(initialValue);
  }, [initialValue, closePopover]);

  useEffect(() => {
    closePopover();
    handleChange(initialValue);
  }, [initialValue, closePopover]);

  const handleSaveUp = () => {
      if(rec) {
          const params = {
              accountIds: [
                  rec.id
              ]
          }
          params[field] = inputValue
          handleSave(params);
          closePopover();
          return
      }
      handleSave(inputValue)
      closePopover();
      return;
  }

  useEffect(() => {
    setTimeout(() => {
      if (showPopover && inputRef.current && inputRef.current.input) {
        inputRef.current.input.focus();
      }
    });
  }, [showPopover]);

  const content = (
    <LoadingBlock loading={isLoading} noMinHeight>
      <div style={{ display: "flex", alignItems: "center" }}>
        {changeBlock({
          value: inputValue,
          onChange: (v) => handleChange(v),
          inputRef,
        })}
        <div className="ml-small flex-ai-center">
          <Button
            size="small"
            variant="contained"
            color="default"
            onClick={handleClosePopover}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={inputValue !== initialValue ?{marginLeft: 5, backgroundColor: "#1976d2"}: {marginLeft: 5,}}
            size="small"
            onClick={handleSaveUp}
            disabled={inputValue === initialValue}
          >
            Save
          </Button>
        </div>
      </div>
    </LoadingBlock>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={showPopover}
      onVisibleChange={handleVisibleChange}
      title={withTitle ? title || 'Edit' : null}
    >
      <span style={{
        cursor: 'pointer',
        position: 'relative',
        width: 'calc(100% - 7px)',
        height: '100%',
        display: 'flex',
        ...popoverContentStyle
      }}>
        <Popover content={titlePopover || 'Edit'}>
          {children}
          <EditTwoTone
            className={iconClassName}
            style={{
              position: 'absolute',
              top: '50%',
              left: 'calc(100% + 1px)',
              transform: 'translateY(-50%)',
              ...iconStyle,
            }}
          />
        </Popover>
      </span>
    </Popover>
  );
};

EditableField.propTypes = {
  children: PropTypes.node.isRequired,
  changeBlock: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  iconClassName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  withTitle: PropTypes.bool,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  iconStyle: PropTypes.object,
  titlePopover: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  popoverContentStyle: PropTypes.object,
};

EditableField.defaultProps = {
  initialValue: null,
  iconClassName: '',
  withTitle: true,
  title: '',
  iconStyle: {},
  titlePopover: '',
  popoverContentStyle: {},
};

export default React.memo(EditableField);
