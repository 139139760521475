import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import AccountService from '../Services/AccountService';

export const fetchAccounts = createAction('accounts/list', (params) => ({
  payload: AccountService.getList(params),
}));

export const createAccount = createAction('accounts/create', (data) => ({
  payload: AccountService.createAccount(data),
}));

export const updateAccounts = createAction('accounts/updates', (data) => ({
  payload: AccountService.updateAccounts(data),
}));

export const changePasswordAccount = createAction('accounts/change-password', (data) => ({
  payload: AccountService.updateAccounts(data),
}));

export const changeTokenAccount = createAction('accounts/change-token', (data) => ({
  payload: AccountService.changeToken(data),
}));

export const changeStatusAccount = createAction('accounts/change-status', (data) => ({
  payload: AccountService.changeStatus(data),
}));

export const fetchGroupBalanceAccount = createAction('accounts/fetch-group-balance', () => ({
  payload: AccountService.fetchAccountGroupBalance(),
}));

export const fetchAuxAccount = createAction('accounts/fetch-aux', () => ({
  payload: AccountService.fetchAux(),
}));
export const fetchAccountsGroups = createAction('accounts/groups', () => ({
  payload: AccountService.fetchAccountsGroups(),
}));

export const disableAccounts = createAction('accounts/disable', (data) => ({
  payload: AccountService.disableAccounts(data),
}));

export const enableAccounts = createAction('accounts/enable', (data) => ({
  payload: AccountService.enableAccounts(data),
}));

const initState = {
  list: getDefaultState(),
  create: getDefaultState(),
  updateAccounts: getDefaultState(),
  changeToken: getDefaultState(),
  groupBalance: getDefaultState(),
  aux: getDefaultState(),
  disableAccounts: getDefaultState(),
  enableAccounts: getDefaultState(),
  changeStatus: getDefaultState(),
  changePassword: getDefaultState(),
  accountsGroups: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAccounts, 'list'),
  ...getDefaultHandler(createAccount, 'create'),
  ...getDefaultHandler(updateAccounts, 'updateAccounts'),
  ...getDefaultHandler(changeTokenAccount, 'changeToken'),
  ...getDefaultHandler(changeStatusAccount, 'changeStatus'),
  ...getDefaultHandler(fetchGroupBalanceAccount, 'groupBalance'),
  ...getDefaultHandler(fetchAuxAccount, 'aux'),
  ...getDefaultHandler(disableAccounts, 'disableAccounts'),
  ...getDefaultHandler(changePasswordAccount, 'changePassword'),
  ...getDefaultHandler(fetchAccountsGroups, 'accountsGroups'),
});
