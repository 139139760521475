import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Button, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { cancelRedeemJobs, fetchRedeemJobs } from '../../Reducers/redeemJobs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CloseCircleOutlined } from "@ant-design/icons/lib/icons";
import JobFilters from '../../Components/JobFilters';
import './styles.scss';
import {LoadingOutlined} from "@ant-design/icons";
import JobsTableWrapper from '../../Components/JobsTableWrapper';

const b = b_.lock('RedeemJob');

function RedeemJob() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTableState(state => state.redeemJobs.list, fetchRedeemJobs);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.redeemJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelRedeemJobs(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  return <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Redeem Jobs
    </Title>
    <JobFilters filters={filters} onFilter={onFilter} className="mb" />
    <JobsTableWrapper
        jobTableType='redeem_jobs_table'
        jobsList={list}
        onChange={onChange}
        pagination={pagination}
        isLoading={isLoading}
        fetch={fetch}
        onFilter={onFilter}
        filters={filters}
      />
  </div>;
}

export default RedeemJob;
