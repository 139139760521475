import React from 'react';
import PropTypes from 'prop-types';
import {Popover, Tag} from 'antd';
import "./styles.scss"

const STATE_TO_COLOR = {
  Enabled: 'green',
  Disabled: 'gray',
  Invalid: 'red',
  TempInvalid: 'yellow',
};

function PrintAccountState({ className, status, handleSave, rec}) {
  const statuses = ["Disable", "Enable"]
  const reverseStatus = statuses.filter(item => item !== status.slice(0, -1))[0].toLowerCase()

  const handleSaveUp = () => {
      handleSave({
          accountIds : [rec.id],
          action : reverseStatus,
      });
  }

  return <Popover content={`Change Status on the ${reverseStatus}`}>
      <Tag style={{ cursor: "pointer"}}
          onClick={handleSaveUp}
          className={[className, status]}
          color={STATE_TO_COLOR[status]}
      >{status}</Tag>
  </Popover>


}

PrintAccountState.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(STATE_TO_COLOR)).isRequired,
  rec: PropTypes.object,
  handleSave: PropTypes.func,
};

PrintAccountState.defaultProps = {
  className: '',
  rec: {},
};

export default PrintAccountState;
