import React, {useState} from 'react'
import {Button, Popover} from "antd";
import './styles.scss'
import {EditTwoTone} from "@ant-design/icons";
import useBoolean from "../../../../Utils/useBoolean";
import TextArea from "antd/lib/input/TextArea";

function UpdateCommentProxies ({value, title, updateProxies, rec, fieldsChange}) {
    const [initial, setValue] = useState(value)
    const {toggleValue: changeVisible, value: visible} = useBoolean(false)

    const handleSave = () => {
        const params = {
            id: rec.id,
        }
        changeVisible()
        fieldsChange.forEach(e => params[e] = rec[e] || "")
        params.comment = initial
        updateProxies(params)
    }
    return (
      <Popover
        title="Update Comment"
        trigger="click"
        visible={visible}
        onVisibleChange={changeVisible}
        content={
          <div className="proxies_change">
            <TextArea
              defaultValue={initial}
              onChange={(e) => setValue(e.target.value)}
            />
            <div className={"ml updateProxiesButton"} style={{height: 32}}>
              <Button
                color="default"
                variant="contained"
                onClick={changeVisible}
              >
                case
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={initial === value}
                style={initial !== value ? { backgroundColor: "#1976d2" } : {}}
                onClick={handleSave}
              >
                save
              </Button>
            </div>
          </div>
        }
      >
          <Popover content={'Edit ' + title}>
              <div>
                  {value}
                  <EditTwoTone
                      className={"anticon"}
                      style={{
                          position: 'absolute',
                          top: '50%',
                          left: 'calc(100% - 15px)',
                          transform: 'translateY(-50%)',
                      }}
                  />
              </div>
          </Popover>
      </Popover>
    );
}
export default UpdateCommentProxies
