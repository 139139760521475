import { createAction, createReducer } from '@reduxjs/toolkit';
import DriverService from '../Services/DriverService';
import { frc } from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchDrivers = createAction('drivers/fetch', () => ({
  payload: DriverService.fetchDrivers(),
}));

export const updateVpnDriver = createAction('drivers/update-vpn', (data) => ({
  payload: DriverService.updateVpn(data),
}));

export const updateDriver = createAction('drivers/update', (data) => ({
  payload: DriverService.update(data),
}));

export const createDriver = createAction('drivers/create', (data) => ({
  payload: DriverService.create(data),
}));

const initState = {
  drivers: getDefaultState(),
  update: getDefaultState(),
  updateVpn: getDefaultState(),
  create: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchDrivers, 'drivers'),
  ...getDefaultHandler(updateVpnDriver, 'updateVpn'),
  ...getDefaultHandler(updateDriver, 'update'),
  ...getDefaultHandler(createDriver, 'create'),
});
