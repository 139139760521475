import { ChromeOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import React from 'react'

const ResetChromeUserButton = ({ id, removeUserData }) => {
  return (
        <Popconfirm title={`Are you sure to reset Chrome user profile?`}
                          onConfirm={() => removeUserData(id)}
                          okText="Yes"
                          cancelText="No"
                          placement="topLeft"
      >
      <Popover placement={"topLeft"} content={"Reset Chrome user profile"}>
        <ChromeOutlined />
      </Popover>
    </Popconfirm>
    
  )
}

export default ResetChromeUserButton
