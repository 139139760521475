import React, {useCallback, useEffect, useState} from 'react';
import {Badge, Input, Progress, Spin, Table, Tag, Tooltip, message} from 'antd';
import b_ from 'b_';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import EditableField from '../../../Components/EditableField';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import ChangePassword from '../ChangePassword';
import './styles.scss';
import {Link} from 'react-router-dom';
import {
    changeStatusAccount, fetchAccountsGroups,
    updateAccounts
} from '../../../Reducers/accounts';
import Expanded from './Expanded';
import EnabledJobs from '../../../Components/EnabledJobs';
import TableSettings from "./TableSettings";
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizeableTitle";
import AccountsChosen from "../AccountsChosen";
import CurrencySelector from "../Selectors/CurrencySelector";
import formatRate from '../../../Utils/formatRate';
import ResetChromeUserButton from '../ChromeUserButtons/ResetChromeUserButton';
import UseDefaultChromeUserButton from '../ChromeUserButtons/UseDefaultChromeUserButton';
import { defaultUserDataDir, removeUserDataDir } from '../../../Reducers/account';
import { fetchConfigs } from '../../../Reducers/config';
import CircularProgressWithData from '../../../Components/CircularProgressWithData';

const b = b_.lock('AccountsTable');

function AccountsTable({
                         list,
                         pagination,
                         onChange,
                         isLoading,
                         afterUpdate,
                         selected,
                         setSelected,
                         onFilter,
                         filters,
                         sorter,
                       }) {

  const fieldsChange = [
      "login",
      "phase",
      "authToken",
      "currency",
      "comment",
      "driver",
      "enabledJobs",
  ]

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfigs())
  }, [dispatch]);

  const loadings = useSelector(state => ({
    updateAccounts: state.accounts.updateAccounts.isLoading,
    changePassword: state.accounts.changePassword.isLoading,
    status: state.account.changeStatus.isLoading,
  }), shallowEqual);

  const {payload: clients} = useSelector(state => state.clients.list)
  const groups = useSelector(state => (state.accounts.accountsGroups.payload));

  useEffect(() => {
      !groups && dispatch(fetchAccountsGroups());
  },[])

  const {accountCodeFilters, countryFilters, balanceLimitPercentage} = useSelector(
    (state) => {
      if (!state.config.data.payload) return {accountCodeFilters: null};

      try {
        let accountCodeFilters = state.config.data.payload.find(({name}) => name === 'accountResultCodeFilter');
        accountCodeFilters = accountCodeFilters ? JSON.parse(accountCodeFilters.value) : null;

        let countryFilters = state.config.data.payload.find(({name}) => name === 'countries');
        countryFilters = accountCodeFilters ? JSON.parse(countryFilters.value) : null;

        let balanceLimitPercentage = state.config.data.payload.find(({name}) => name === "balanceLimitPercentage");
        balanceLimitPercentage = balanceLimitPercentage ? JSON.parse(balanceLimitPercentage.value) : null;

        return {accountCodeFilters, countryFilters, balanceLimitPercentage};
      } catch (e) {
        return {accountCodeFilters: null, countryFilters: null, balanceLimitPercentage: null};
      }
    },
    shallowEqual
  );

  console.log(accountCodeFilters, countryFilters, balanceLimitPercentage);

  if (balanceLimitPercentage) {
      let balanceLimitPercentageToValue = balanceLimitPercentage / 100;
      localStorage.setItem('balanceLimitPercentage', balanceLimitPercentageToValue);
  }

  let balanceLimitPercentageRetreived = +localStorage.getItem('balanceLimitPercentage');

    const updateAccount = useCallback((data) => {
        dispatch(updateAccounts(data)).then(() => {
            message.success('account has been updated!');
            afterUpdate();
        }).catch(error => {
            console.log(error);
            message.error('Can\'t update Enabled Jobs.');
        });
    }, [dispatch, afterUpdate]);

    const updateAccountState = useCallback((data) => {
        dispatch(changeStatusAccount(data)).then(() => {
            message.success('account state has been updated!');
            afterUpdate();
        }).catch(error => {
            console.log(error);
            message.error('Can\'t update state.');
        });
    }, [dispatch, afterUpdate]);

    const [AllCheckbox, setAllCheckbox] = useStorageState('columnsAccountsTable', {
        checkedList: [],
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateAccountGroups = useCallback((data) => {
        dispatch(updateAccounts(data)).then(() => {
            message.success('account groups has been updated!');
            afterUpdate();
        }).catch(error => {
            console.log(error);
            message.error('Can\'t update groups.');
        });
    }, [dispatch, afterUpdate]);

    const removeUserData = useCallback((id) => {
      dispatch(removeUserDataDir(id)).then(() => {
        message.success('User Chrome data has been reset!');
        afterUpdate();
      }).catch(error => {
        console.log(error);
        message.error('Can\'t reset Chrome user data');
      })
    }, [dispatch]);
  
      const defaultUserData = useCallback((id) => {
          dispatch(defaultUserDataDir(id)).then(() => {
              message.success('Default User Chrome data Chrome copied!');
              afterUpdate();
          }).catch(error => {
              console.log(error);
              message.error('Can\'t use default user chrome data ');
          })
      }, [dispatch]);
     


  const GetColumnTable = useCallback(() => {
    return [
        {
            dataIndex:"id",
            title:"ID",
            align: "center",
            width: 58,
            key: "id",
            sorter: sorter ? (a, b) => a - b : null,
            render:id => <Link to={`account/${id}`}>{id}</Link>,
        },
        {
            dataIndex:"login",
            title:"Login",
            align: "center",
            key: "login",
            width: 200,
            render:(login, rec) => (
                <EditableField handleSave={updateAccount}
                       fieldsChange={fieldsChange}
                       field={"login"}
                       rec={rec}
                       title="Update Login"
                       withTitle
                       isLoading={isLoading}
                       iconClassName={b('edit')}
                       titlePopover="Edit Login"
                       initialValue={login}
                       changeBlock={({onChange, ...props}) => (
                           <Input {...props}
                                  size="small"
                                  onChange={e => onChange(e.target.value)}/>
                       )}>
                    {login}
                </EditableField>)
        },
        {
            dataIndex:"phase",
            title:"Phase",
            align: "center",
            width: 85,
            key: "phase",
            sorter: sorter ? (a, b) => a - b : null,
            render:(value, rec) => <div>
                {value || "Unknown"}
            </div>
        },
        {
            dataIndex: "status",
            title: "Status",
            align: "center",
            key: "status",
            width: 100,
            sorter: sorter ? (a, b) => a - b : null,
            render: (status, rec) => (<PrintAccountState
                fieldsChange={fieldsChange}
                status={status}
                rec={rec}
                handleSave={updateAccountState}/>)
        },

        {
            dataIndex:"enabledJobs",
            title:"Enabled Jobs",
            key: 'enabledJobs',
            align: "center",
            width: 60,
            render:(enabledJobs, rec) => (
                <EnabledJobs
                    fieldsChange={fieldsChange}
                    enabledJobs={enabledJobs}
                    field={"enabledJobs"}
                    rec={rec}
                    iconClassName={b('edit')}
                    handleSave={updateAccount}
                />)
        },
        {
            dataIndex:"currency",
            title:"Currency",
            align: "center",
            key: "currency",
            width: 60,
            render:(value, rec) =>
                <EditableField handleSave={updateAccount}
                               fieldsChange={fieldsChange}
                               field={"currency"}
                               rec={rec}
                               title="Update Currency"
                               titlePopover="Edit Currency"
                               iconClassName={b('edit')}
                               withTitle
                               isLoading={isLoading}
                               initialValue={value}
                               changeBlock={(props) => (
                                   <CurrencySelector {...props}
                                                    size="small"
                                                    className="w100"
                                   />
                               )}>
                    {value}
                </EditableField>
        },
        {
          dataIndex: "balance",
          title: "Balance",
          align: "center",
          key: "balance",
          defaultView: true,
          width: 100,
          sorter: sorter ? (a, b) => a - b : null,
          render: (_, rec) => {
                  const balanceLimitPercent = rec.balLimit * balanceLimitPercentageRetreived;
                  const isLimitReached = rec.balance > balanceLimitPercent;
                  const isBalanceWarning = !isLimitReached && (balanceLimitPercent * 1.2) < rec.balance;
                  const balanceLimitProgress = (rec.balance / balanceLimitPercent) * 100
                  
                  const cellContent = (
                    
                    <>
                      <CircularProgressWithData balance={rec.balance} percent={balanceLimitProgress} balanceLimitPercent={balanceLimitPercent} />
                    </>
                   
                  );
            return cellContent;    
          },
        },
        {
            dataIndex:"balLimit",
            title:"Limit",
            align:"center",
            key: "balLimit",
            width: 77,
            sorter: sorter ? (a, b) => a - b : null,
            render:(value, rec) => (
                <EditableField handleSave={updateAccount}
                   fieldsChange={fieldsChange}
                   rec={rec}
                   field={"balLimit"}
                   title="Update BalLimit"
                   titlePopover="Edit BalLimit"
                   iconClassName={b('edit')}
                   withTitle
                   isLoading={isLoading}
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <Input {...props}
                              size="small"
                              type="number"
                              onChange={e => onChange(+e.target.value)}/>
                   )}>
                    <PrintPrice value={value}/>
                </EditableField>)
        },
        {
          dataIndex:"avgRate",
          title:"Avg. Rate",
          align:"center",
          key: "avgRate",
          width: 97,
          sorter: sorter ? (a, b) => a - b : null,
          render:value => value && !isNaN(value) ? <div>{formatRate(value)}%</div> : <div>no data</div>,
      },
        {
            dataIndex:"driver",
            title:"Driver",
            width: 40,
            align:"center",
            key: "driver",
            sorter: sorter ? (a, b) => a - b : null,
            render:(value, rec) =>(
                <EditableField handleSave={updateAccount}
                   fieldsChange={fieldsChange}
                   field={"driver"}
                   rec={rec}
                   title="Update Driver"
                   titlePopover="Edit Driver"
                   isLoading={isLoading}
                   iconClassName={b('edit')}
                   withTitle
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <Input {...props}
                              size="small"
                              type="number"
                              onChange={e => onChange(e.target.value)}/>
                   )}>
                    {value}
                </EditableField>)
        },
        {
            dataIndex:"comment",
            title:"Comment",
            align: "center",
            key: "comment",
            width: 109,
            sorter: sorter ? (a, b) => a - b : null,
            render:(value, rec) =>
                <EditableField handleSave={updateAccount}
                   fieldsChange={fieldsChange}
                   field={"comment"}
                   rec={rec}
                   title="Update Comment"
                   titlePopover="Edit Comment"
                   isLoading={isLoading}
                   iconClassName={b('edit')}
                   withTitle
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <TextArea {...props}
                                 size="small"
                                 onChange={e => onChange(e.target.value)}/>
                   )}>
                    {value}
                </EditableField>
        },
        {
            dataIndex:"resultCode",
            title:"Result Code",
            align: "center",
            width: 200,
            key: "resultCode",
            sorter: sorter ? (a, b) => a - b : null,
        },
        {
            dataIndex:"id",
            title:"Actions",
            align: "center",
            key: "id",
            width: 120,
            render:(id, rec) => (<div className={b('actions')}>
                <div className={b('icon')}>
                    <AccountsChosen accountIds={id}
                                    accountsGroups={rec.groups}
                                    groups={groups}
                                    addAccountChosen={updateAccountGroups}
                    />
                    <ChangePassword className="mr-small" accountIds={id}/>
                    <ResetChromeUserButton id={id} removeUserData={removeUserData} />
                    <UseDefaultChromeUserButton  id={id} defaultUserData={defaultUserData} />
                </div>
            </div>)
        },
      ]; 
    }, [filters, list])

  const [columns, setColumns] = useState(GetColumnTable());

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList:GetColumnTable().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setColumns(GetColumnTable())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, clients, countryFilters?.length, isLoading])
    
    const columnsFilter = columns.filter(column => AllCheckbox.checkedList.indexOf(column.title) !== -1);
    const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

    

    return <Table bordered
                className={b()}
                scroll={{ x: 'max-content' }}
                size="middle"
                dataSource={list}
                onChange={onChange}
                columns={columnsFilter}
                pagination={pagination}
                loading={loader}
                onFilter={onFilter}
                rowClassName={(row) => row.status === "Processing" ? 'lock disable' : 'lock'}
                rowKey={(record) => `${record.id}_${record.balance}`}
                title={() => <TableSettings
                    onChangeCheckAll={onChangeCheckAll}
                    allChecks={AllCheckbox}
                    Checkboxes={AllCheckbox.checkedList}
                    TableColumn={() =>GetColumnTable()}
                    setCheckbox={onChangeCheckList}
                />}
                rowSelection={{
                  selectedRowKeys: selected,
                  onChange: setSelected,
                }}
                expandable={{
                  expandedRowRender: record => <Expanded record={record}/>,
                }}
  >
  </Table>;
}

export default AccountsTable;
