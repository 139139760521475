import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { accessInfo } from '../../Reducers/account'
import { useParams } from 'react-router-dom';

const AccountAccess = () => {

  const params = useParams();
  const [accountInfo, setAccountInfo] = useState()

  const accId = +params.id

  console.log('props', accId)
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(accessInfo(accId))
    .then((res) => {
      console.log('result', res)
      setAccountInfo(res)
    })
  })

  return (
    <div>{JSON.stringify(accountInfo)}</div>
  )
}

export default AccountAccess
