import React from 'react';
import {Col, Row} from 'antd';
import b_ from 'b_';
import { JSONTree } from 'react-json-tree';
import theme from '../../../../Utils/jsonTheme';
import './styles.scss';

const b = b_.lock('Expanded');

function Expanded({className, record}) {
  const {resultDetails, errors, state, extState} = record;

  console.log(errors, record)

  return <div className={`${b()} ${className}`}>
    <Row>
      <Col style={{maxWidth: "460px"}}>
        {resultDetails && resultDetails.length !== 0 &&
        <div className={b('container')}>
          <b className="block mb">Details</b>
          <JSONTree hideRoot theme={theme} data={resultDetails} shouldExpandNode={() => true}/>
        </div>
        }
      </Col>
      <Col style={{maxWidth: "460px"}}>
        {errors && Object.keys(errors).length !== 0 &&
        <div className={b('container')}>
          <b className="block mb">Errors</b>
          <JSONTree hideRoot theme={theme} data={errors} shouldExpandNode={() => true}/>
        </div>
        }
      </Col>
      <Col style={{maxWidth: "460px"}}>
        {state && Object.keys(state).length !== 0 &&
        <div className={b('container')}>
          <b className="block mb">State</b>
          <JSONTree hideRoot theme={theme} data={state} shouldExpandNode={() => true}/>
        </div>
        }
      </Col>
      <Col style={{maxWidth: "460px"}}>
        {extState && Object.keys(extState).length !== 0 &&
        <div className={b('container')}>
          <b className="block mb">ExtState</b>
          <JSONTree hideRoot theme={theme} data={extState} shouldExpandNode={() => true}/>
        </div>
        }
      </Col>
    </Row>
  </div>;
}

export default Expanded;
