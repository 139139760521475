import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Title from 'antd/lib/typography/Title';
import { Table } from 'antd';
import './styles.scss';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import ErrorBlock from '../../../Components/ErrorBlock';
import PrintPercent from '../../../Components/Prints/PrintPercent';
import {fetchGroupBalanceAccount} from "../../../Reducers/accounts";
import formatRate from '../../../Utils/formatRate';

const b = b_.lock('AccountGroupBalance');

function AccountGroupBalance({ className }) {
  const { payload, isLoading, error } = useSelector(state => state.accounts.groupBalance, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGroupBalanceAccount());
  }, [dispatch]);

  return (<div className={className}>
    <Title level={2} style={{width: "800px"}} className="mb">
      Account Group Balance
    </Title>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <Table bordered
           dataSource={payload}
           loading={isLoading}
           scroll={{ x: "max-content" }}
           rowKey="country"
           className={b('table')}
           pagination={false}
    >
      <Table.Column title="Group" align="center" dataIndex="group" />
      <Table.Column title="All"
                    align="center"
                    dataIndex="allBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        {rec.group !== 'ALL'
                          ? <div>
                              {rec.groupCurrency !== 'EUR'
                                ? <PrintPrice value={bal} currency={rec.groupCurrency} /> : ''
                              } (<PrintPrice value={rec.allBalEur} currency="EUR" />)
                            </div>
                          : <PrintPrice value={rec.allBalEur} currency="EUR" />
                        }
                        &nbsp;<small className={b('count')}>({rec.allCnt})</small>
                      </div>
                    )}
      />
      <Table.Column title="Enabled"
                    align="center"
                    dataIndex="enBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        {rec.group !== 'ALL'
                          ? <div>
                              {rec.groupCurrency !== 'EUR'
                                ? <PrintPrice value={bal} currency={rec.groupCurrency} /> : ''
                              } (<PrintPrice value={rec.enBalEur} currency="EUR" />)
                            </div>
                          : <PrintPrice value={rec.enBalEur} currency="EUR" />
                        }
                        &nbsp;<small className={b('count')}>({rec.enCnt})</small>
                      </div>
                    )}
      />
      <Table.Column title="Invalid"
                    align="center"
                    dataIndex="invBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        {rec.group !== 'ALL'
                          ? <div>
                              {rec.groupCurrency !== 'EUR'
                                ? <PrintPrice value={bal} currency={rec.groupCurrency} /> : ''
                              } (<PrintPrice value={rec.invBalEur} currency="EUR" />)
                            </div>
                          : <PrintPrice value={rec.invBalEur} currency="EUR" />
                        }
                        &nbsp;<small className={b('count')}>({rec.invCnt})</small>
                      </div>
                    )}
      />
      <Table.Column title="Disabled"
                    align="center"
                    dataIndex="disBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        {rec.group !== 'ALL'
                          ? <div>
                              {rec.groupCurrency !== 'EUR'
                                ? <PrintPrice value={bal} currency={rec.groupCurrency} /> : ''
                              } (<PrintPrice value={rec.disBalEur} currency="EUR" />)
                            </div>
                          : <PrintPrice value={rec.disBalEur} currency="EUR" />
                        }
                        &nbsp;<small className={b('count')}>({rec.disCnt})</small>
                      </div>
                    )}
      />
      <Table.Column
          title="Codes Rates"
          align="center"
          dataIndex="codesRates"
          render={value => value && !isNaN(value) ? <div>{formatRate(value)}%</div> : <div>no data</div>}
      />
      <Table.Column title="Balance to ALL %"
                    align="center"
                    dataIndex="balToAll"
                    render={value => <PrintPercent value={value} />}
      />
    </Table>
  </div>);

}

export default AccountGroupBalance;
