import React, { useCallback } from 'react';
import b_ from 'b_';
import { Layout as AntLayout } from 'antd';
import { Outlet } from 'react-router-dom';
import './styles.scss';
import { frc } from 'front-end-common';
import { history } from '../../Boot/createStore';
import { links } from '../../Boot/Routes';
import { useDispatch } from 'react-redux';
import { authSignOut } from '../../Reducers/auth';

const Content = AntLayout.Content;

const b = b_.lock('Layout');

const { Header } = frc.components

function Layout() {

  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(authSignOut());
  }, [dispatch]);

  return <AntLayout  className={b()}>
    <AntLayout style={{flexDirection: "column"}}>
      <Header className={b('header', { isCollapsed: true })}
              history={history} 
              links={links} 
              defaultKey={"blizzard"} 
              handleLogout={handleLogout}
      />
      <Content className={b('content', { isCollapsed: true })}>
      <Outlet />
      </Content>
    </AntLayout>
  </AntLayout>;
}

export default Layout;
