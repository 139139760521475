import React, {useCallback, useEffect, useState} from 'react';
import {message} from 'antd';
import b_ from 'b_';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import './styles.scss';
import {Link} from 'react-router-dom';
import Expanded from './Expanded';
import EnabledJobs from '../../../Components/EnabledJobs';
import TableSettings from "./TableSettings";
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizeableTitle";


const b = b_.lock('AccountsTable');

function ArchiveAccountsTable({
                         archiveList,
                         pagination,
                         onChange,
                         isLoading,
                         afterUpdate,
                         selected,
                         setSelected,
                         onFilter,
                         filters,
                         sorter,
                         accountsLocal,
                       }) {
  const dispatch = useDispatch();

  const loadings = useSelector(state => ({
    enabledJobs: state.accounts.enableAccounts.isLoading,
  }), shallowEqual);

  const {payload: clients} = useSelector(state => state.clients.list)

  const updateEnabledJobs = useCallback((id, enabledJobs) => {
      message.error('Can\'t update Enabled Jobs.');
  }, [dispatch, afterUpdate]);

  const updateStatus = useCallback((id, status) => {
      message.error('Can\'t update status');
  }, [dispatch, afterUpdate]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('columnsArchiveAccountsTable', {
      checkedList: [],
      indeterminate: false,
      checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
      setAllCheckbox({
          checkedList,
          indeterminate: !!checkedList.length && checkedList.length < columns.length,
          checkAll: checkedList.length === columns.length,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetColumnTable = (sorter,filterMultiple) => ([
      {
          dataIndex:"id",
          title:"ID",
          align: "center",
          defaultView: true,
          width: 50,
          filterMultiple,
          sorter: (a, b) => a - b,
          render:id => <Link to={`account/${id}`}>{id}</Link>,
      },
      {
          dataIndex:"login",
          title:"Login",
          align: "center",
          defaultView: true,
          width: 150,
          render:(login, rec) => (<div>{login}</div>)
      },
      {
          dataIndex: "status",
          title: "Status",
          align: "center",
          defaultView: true,
          width: 50,
          sorter: (a, b) => a - b,
          render: (status, rec) => (<PrintAccountState handleSave={updateStatus} status={status}/>)
      },

      {
          dataIndex:"enabledJobs",
          title:"Enabled Jobs",
          align: "center",
          defaultView: true,
          width: 70,
          render:(enabledJobs, rec) => (
              <EnabledJobs
                  field={"enabledJobs"}
                  enabledJobs={enabledJobs}
                  iconClassName={b('edit')}
                  handleSave={updateEnabledJobs}
              />)
      },
      {
          dataIndex:"country",
          title:"Country",
          align: "center",
          defaultView: true,
          width: 30,
          sorter: (a, b) => a - b,
          render:(value, rec) => <div>{value}</div>
      },
      {
          dataIndex:"balance",
          title:"Balance",
          defaultView: true,
          align:"center",
          width: 70,
          sorter: (a, b) => a - b,
          render:value => <PrintPrice value={value}/>,
      },
      {
          dataIndex:"balLimit",
          title:"Limit",
          defaultView: true,
          align:"center",
          width: 70,
          sorter: (a, b) => a - b,
          render:(value, rec) => (<div>{value}</div>)
      },
      {
          dataIndex:"purchLimit",
          title:"Purchase Limit",
          defaultView: true,
          width: 100,
          align:"center",
          sorter: (a, b) => a - b,
          render:(value, rec) =>(<div>{value}</div>)
      },
      {
          dataIndex:"proxy",
          title:"Proxy",
          align: "center",
          defaultView: true,
          width: "200px",
          sorter: (a, b) => a - b,
          render:(value, rec) =>(<div>{value}</div>)
      },
      {
          dataIndex:"driver",
          title:"Driver",
          defaultView: true,
          width: 100,
          align:"center",
          sorter: (a, b) => a - b,
          render:(value, rec) =>(<div>{value}</div>)
      },
      {
          dataIndex:"comment",
          title:"Comment",
          align: "center",
          defaultView: true,
          width: 100,
          sorter: (a, b) => a - b,
          render:(value, rec) => <div>{value}</div>
      },
      {
          dataIndex:"resultCode",
          title:"Result Code",
          align: "center",
          defaultView: true,
          width: 100,
          sorter: (a, b) => a - b,
      }
  ])

  const [columns, setColumns] = useState(GetColumnTable(sorter,true));

  useEffect(() => {
      if(AllCheckbox.checkAll) {
          setAllCheckbox({
              checkedList:GetColumnTable(sorter,true).map(e => e.title),
              indeterminate: false,
              checkAll: true,
          })
      }
  },[AllCheckbox.checkAll])

  const onChangeCheckAll = useCallback((e) => {
      setAllCheckbox({
          checkedList: (e.target.checked && columns.map(e => e.title)) || [],
          indeterminate: false,
          checkAll: e.target.checked,
      });
      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      setColumns(GetColumnTable(sorter,true))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter, accountsLocal, filters, clients])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

  return <ResizeTable bordered
              className={b()}
              scroll={{x: 'max-content'}}
              dataSource={archiveList}
              onChange={onChange}
              columns={columnsFilter}
              pagination={pagination}
              loading={loader}
              onFilter={onFilter}
              size="small"
              rowClassName={() => b('lock')}
              rowKey="id"
              style={{ wordBreak: "break-word", wordWrap: "break-word" }}
              title={() => <TableSettings
                  onChangeCheckAll={onChangeCheckAll}
                  allChecks={AllCheckbox}
                  Checkboxes={AllCheckbox.checkedList}
                  TableColumn={() =>GetColumnTable(sorter,true)}
                  setCheckbox={onChangeCheckList}
              />}
              rowSelection={{
                selectedRowKeys: selected,
                onChange: setSelected,
              }}
              expandable={{
                expandedRowRender: record => <Expanded record={record}/>,
              }}
  >
  </ResizeTable>;
}

export default ArchiveAccountsTable;
